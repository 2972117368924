.slide_container {
	/* background-color: yellow; */
	width: 100%;
	justify-content: center;
	align-items: center;
}

.icon_container {
	position: absolute;
	z-index: 2;
	height: 100%;
	width: 100%;
	justify-content: space-between;
	align-items: center;
	color: white;
}


.icon_box {
	/* background-color: yellow; */
	height: 60%;
	align-items: center;
	justify-content: center;
	width: 160px;
}



.image {
	height: auto;
	width: 100%;
	object-fit: contain;
}




