@font-face {
	font-family: "test";
	src: local("test"),
	url("Fonts/test_bold.woff2") format("truetype");
	font-weight: bold;
}
		
		
		
@font-face {
	font-family: "test";
	src: local("test"),
	url("Fonts/test_regular.woff2") format("truetype");
	font-weight: normal;
}
		




.header {


}



.container {
	/* background-color: orange; */
	width: 100%;

	padding-left: 16px;
	padding-right: 16px;
	flex-direction: column;
	align-items: center;
}


