.container {
	flex-direction: column;
	width: 100%;
	align-items: center;
	
	
}



.video_container {
	margin-top: 100px;
	/* background-color: orange; */
	width: 100vw;
	justify-content: center;
	align-items: center;
	
	/* pointer-events: none; */
	

}


.content_onVideo {
	max-width: 600px;
	position: absolute;
	
}






.content_container {
	flex-direction: column;
	align-items: center;
	
	/* background-color: yellow; */
	/* padding-left: 28px; */

	max-width: 800px
}