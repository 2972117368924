.container {
	flex-direction: column;
	align-items: center;

}

.title_container {


}

.body_container {


}

