.container {
	width: 100%;
	background-color: #000098;
	padding: 4px
}

.side_container {
	background-color: yellow;
	flex: 1;
	justify-content: center;
	align-items: center;
	font-size: 19px;
	font-weight: 700;
	padding: 12px 6px 12px 6px;
	text-align: center;
	transition: all 0.5s
}

