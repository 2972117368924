.container {
	background-color: #000098;
	color: white;
	flex-direction: column;
	align-items: center;
	width: 100vw;
	padding: 32px;	
	


}

.title {
	align-items: center;
	white-space: pre-line;
	text-align: center;
	font-size: 34px;
	font-weight: 700;
	padding-top: 32px;
	padding-bottom: 32px;

}

.navigation_container {

	max-width: 400px;


}

.list_container {
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	flex-wrap: wrap;
}



.list_element {
	justify-content: center;
	width: 49%;
	font-size: 18px;
	font-weight: 700;
	padding-bottom: 16px;
	border: 5px solid white;
	padding: 12px;
	text-align: center;
	margin-bottom: 2px;


}


.contact_container {
	padding: 40px;

}

.copyright {
	font-size: 13px;
	

}