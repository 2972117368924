.container {
	flex-direction: column;

	/* background-color: orange; */
	width: 100%;


}

.row_container {
	justify-content: space-between;
	width: 100%;
	/* background-color: orange; */
	margin-bottom: 16px;
}

.text_left {
	font-size: 20px;


}

.text_right {
	font-size: 20px;
	font-weight: 700;
	

}



.footer_text {
	margin-top: 20px;
	font-size: 14px;

}