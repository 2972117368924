body {
  margin: 0;
  font-family: "test", sans-serif;
  box-sizing: border-box;
  zoom: unset;
  -webkit-text-size-adjust: none;
  background-color: white;
}


div {
	box-sizing: border-box;
	display: flex;
	font-size: 30px;
	font-weight: 500;
	cursor: default;
	/* transition: all 0.75s; */
}




h1 {
	font-size: 38px;
	text-align: center;
	font-weight: 700;
	letter-spacing: -0.1px;
	margin: 0px;
	padding: 0px;
	margin-bottom: 20px;
	white-space: pre-line;
}

h3 {
	font-size: 30px;
	text-align: center;
	font-weight: 700;
	letter-spacing: -0.1px;
	margin: 0px;
	padding: 0px;
	margin-top: 40px;
	margin-bottom: 16px;
	white-space: pre-line;
}





p {
	font-weight: 400;
	font-size: 18px;
	text-align: center;
	margin: 0px;
	padding: 0px;
	line-height: 26px;
	white-space: pre-line;

}