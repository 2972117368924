
.container {
	padding-top: 100px;
	flex-direction: column;
	align-items: center;
	width: 100%;
}


.content_container {
	width: 100%;
	max-width: 680px;
	flex-direction: column;
}


.spec_container {
	margin-top: 32px;
	flex-direction: column;
}


.header_container {
	flex-direction: column;
	margin-bottom: 32px;


}


.image {
	width: 100%;
	height: auto;

}

