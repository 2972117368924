
.spec_container {
	flex-direction: column;
	width: 100%;
	padding: 20px;
	


}

.specs_row {
	/* background-color: yellow; */
	width: 100%;
	margin-bottom: 20px;
	align-items: center;
	padding-left: 2px;





}

.icon_container {
	width: 50px;
	color: #000098;
}



.specs_container {
	justify-content: space-between;
	width: 100%;
	align-items: center;
	

}

.specs_text {
	font-size: 19px;
	margin-left: 4px;


}

.specs_value {
	font-size: 19px;
	font-weight: 700;
	text-align: right;


}