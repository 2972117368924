.container {
	transition: 0.5s all;
	border: 3px solid #000098;
	justify-content: center;
	align-items: center;
	padding: 16px;

}

.text {
	transition: 0.5s all;
	font-size: 20px;
	font-weight: 700;
	color: #000098
}

