.container {
	position: fixed;
	z-index: 12;
	background-color: #000098;
	color: white;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: 100vh;
	width: 100vw;
	


}

.close_container {
	position: absolute;
	right: 0px;
	top: 0px;
	height: 60px;
	width: 60px;
	justify-content: center;
	align-items: center;
}

.content_container {



}

