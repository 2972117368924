.container {
	margin-top: 16px;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	/* background-color: yellow; */
	border: 3px solid #010097;
	box-shadow: 0px 4px 25px rgba(120, 120, 120, 0.15);
	margin-bottom: 32px;


}

.image_container {
	/* background-color: orange; */
	max-width: 100%;
	height: auto;
	
}

.spec_container {
	flex-direction: column;
	width: 100%;
	padding: 20px;
	


}

.specs_row {
	/* background-color: yellow; */
	width: 100%;
	margin-bottom: 14px;
	align-items: center;






}

.specs_icon {
	width: 46px;



}

.specs_container {
	justify-content: space-between;
	align-items: center;
	
	width: 100%;

}

.specs_text {
	font-size: 19px;


}

.specs_value {
	font-size: 19px;
	font-weight: 700;
	text-align: right;


}

.button {
	background-color: #010097;
	color: white;
	font-size: 20px;
	font-weight: 600;
	width: 100%;
	justify-content: center;
	padding: 24px;
	letter-spacing: 0.325px;
	


}

