.container {
	z-index: 12;
	position: fixed;
	height: 100vh;
	width: 100vw;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	color: white;
	background-color: #000098;
	padding-top: 100px;
	padding-bottom: 40px;

	

}


.nav_container {
	flex-direction: column;
	align-items: center;


}

.menu_element {
	width: 360px;
	margin-bottom: 4px;
	justify-content: center;
	border: 5px solid white;
	padding: 14px;
	font-size: 28px;
	font-weight: 700;
	color: white;
	text-align: center;
}


.footer_container {
	flex-direction: column;
	align-items: center;

}


.footer_title {
	font-size: 24px;
	padding-bottom: 16px;
	font-weight: 700;


}



.footer_number {
	font-size: 24px;
	padding-bottom: 17px;


}

.footer_name {

	font-size: 15px;
	font-weight: 700;

}
