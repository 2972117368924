
.footer_container {
	flex-direction: column;
	align-items: center;

}


.footer_title {
	font-size: 24px;
	padding-bottom: 16px;
	font-weight: 700;


}



.footer_number {
	font-size: 24px;
	padding-bottom: 18px;


}

.footer_name {

	font-size: 15px;
	font-weight: 700;

}


